import { defineStore } from 'pinia'
import axios, { AxiosError } from 'axios'
import moment from 'moment-timezone'
import router from '../router'

interface User {
    id: number
    name: string
    email: string
    email_hash: string
    team_id?: number | null
    created_at: string
    updated_at: string
}

interface Team {
    id: number
    name: string
    users: User[]
    created_at?: string
    updated_at?: string
}

interface LoginResponse {
    token: string
    refresh_token: string
    refresh_token_expiration: string
}

interface AuthState {
    user: User | null
    team: Team | null
    authEndpoint: string
    teamEndpoint: string
    logged: boolean
    isLoading: boolean
}

interface UpdateTeamPayload {
    team: Team
    form: Record<string, any>
}

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        user: null,
        team: null,
        authEndpoint: '/users',
        teamEndpoint: '/teams',
        logged: !!window.localStorage.getItem('token'),
        isLoading: false
    }),

    getters: {
        isLogged: (state): boolean => state.logged,
        userInfo: (state): User => {
            if (state.user === null) {
                return {
                    id: 0,
                    name: '',
                    email: '',
                    email_hash: '',
                    team_id: null,
                    created_at: '',
                    updated_at: ''
                }
            }
            return state.user
        },
        teamInfo: (state): Team | null => state.team,
        isInitialized: (state): boolean => !state.isLoading && (!!state.user || !state.logged)
    },

    actions: {
        // Auth actions
        setUser(user: User): void {
            this.user = user
            if (user) {
                this.fetchTeam(user).catch(error => {
                    console.error('Error fetching team data after setting user:', error)
                })
            }
        },

        async initialize(): Promise<void> {
            const token = window.localStorage.getItem('token')
            if (!token) {
                this.logout()
                return
            }

            const expiresAt = window.localStorage.getItem('expiresAt')
            if (expiresAt && moment.utc(expiresAt).isBefore(moment.utc())) {
                // Token expired, try to refresh it
                const refreshToken = window.localStorage.getItem('refreshToken')
                if (refreshToken) {
                    try {
                        await this.refreshToken(refreshToken)
                        // After successful refresh, continue with initialization
                    } catch (error) {
                        console.error('Error refreshing token:', error)
                        this.logout()
                        return
                    }
                } else {
                    this.logout()
                    return
                }
            }

            try {
                this.isLoading = true
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
                await this.fetchUser()
            } catch (error) {
                console.error('Error initializing auth store:', error)
                this.logout()
            } finally {
                this.isLoading = false
            }
        },

        async login(data: LoginResponse): Promise<void> {
            try {
                this.isLoading = true
                this.logged = true
                
                // Parse JWT token to get expiration time
                const tokenParts = data.token.split('.')
                const tokenPayload = JSON.parse(atob(tokenParts[1]))
                const expiresAt = moment.unix(tokenPayload.exp).utc().format()
                
                // Store token information
                window.localStorage.setItem('token', data.token)
                window.localStorage.setItem('refreshToken', data.refresh_token)
                window.localStorage.setItem('expiresAt', expiresAt)
                
                // Set authorization header
                axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
                
                await this.fetchUser()
                router.push({ name: 'TaskList' })
            } catch (error) {
                console.error('Error during login:', error)
                this.logout()
            } finally {
                this.isLoading = false
            }
        },

        logout(): void {
            this.logged = false
            this.user = null
            this.clearTeam()
            window.localStorage.removeItem('userId')
            window.localStorage.removeItem('token')
            window.localStorage.removeItem('refreshToken')
            window.localStorage.removeItem('expiresAt')
            delete axios.defaults.headers.common['Authorization']
            if (router.currentRoute.value.name !== 'Login') {
                router.push({ name: 'Home' })
            }
        },

        async refreshToken(refreshToken: string): Promise<boolean> {
            try {
                const { data } = await axios.post<LoginResponse>('/auth/token/refresh', {
                    refresh_token: refreshToken
                })
                
                // Parse JWT token to get expiration time
                const tokenParts = data.token.split('.')
                const tokenPayload = JSON.parse(atob(tokenParts[1]))
                const expiresAt = moment.unix(tokenPayload.exp).utc().format()
                
                // Update stored tokens
                window.localStorage.setItem('token', data.token)
                window.localStorage.setItem('refreshToken', data.refresh_token)
                window.localStorage.setItem('expiresAt', expiresAt)
                
                // Update axios header
                axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`
                
                // Emit token updated event to trigger Pusher reconfiguration
                this.$patch({ logged: true })
                
                return true
            } catch (error) {
                const axiosError = error as AxiosError
                console.error('Failed to refresh token:', axiosError)
                
                // Let the axios interceptor handle the redirection
                // Just return false to indicate failure
                return false
            }
        },

        async fetchUser(): Promise<void> {
            try {
                const { data } = await axios.get<{ data: User }>('/auth/me')
                window.localStorage.setItem('userId', data.data.id.toString())
                this.setUser(data.data)
            } catch (error) {
                console.error('Error fetching user:', error instanceof Error ? error.message : 'Unknown error')
                throw error
            }
        },

        // Team actions
        setTeam(team: Team): void {
            this.team = team
        },

        addTeam(team: Team): void {
            this.team = team
        },

        updateTeam(team: Team): void {
            this.team = team
        },

        clearTeam(): void {
            this.team = null
        },

        async fetchTeam(user: User): Promise<boolean> {
            if (!user) {
                console.warn('Cannot fetch team: User is null')
                return false
            }

            this.clearTeam()
            if (user.team_id) {
                try {
                    const { data } = await axios.get<{ data: Team }>(this.teamEndpoint + '/' + user.team_id)
                    this.setTeam(data.data)
                    return true
                } catch (error) {
                    const axiosError = error as AxiosError
                    console.error('Error fetching team:', axiosError.message)
                    if (axiosError.response?.status === 404) {
                        // If team not found, clear the team_id from user
                        this.setUser({ ...user, team_id: null })
                        return false
                    }
                    throw error
                }
            }
            return false
        },

        async updateTeamAction({ team, form }: UpdateTeamPayload): Promise<Team> {
            try {
                const { data } = await axios.patch<{ data: Team }>(this.teamEndpoint + '/' + team.id, form)
                this.updateTeam(data.data)
                return data.data
            } catch (error) {
                const axiosError = error as AxiosError
                console.error('Error updating team:', axiosError.message)
                throw error
            }
        },

        // Password reset actions
        async requestPasswordReset(email: string): Promise<void> {
            try {
                await axios.post('/auth/password/email', { email })
            } catch (error) {
                const axiosError = error as AxiosError
                console.error('Error requesting password reset:', axiosError.message)
                throw error
            }
        },

        async resetPassword(payload: { token: string, password: string }): Promise<void> {
            try {
                await axios.post('/auth/password/reset', payload)
            } catch (error) {
                const axiosError = error as AxiosError
                console.error('Error resetting password:', axiosError.message)
                throw error
            }
        }
    }
}) 